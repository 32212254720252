import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestCard = _resolveComponent("RequestCard")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visibleModel,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visibleModel) = $event)),
    class: "request-pop-up-modal",
    width: "1300px",
    footer: null,
    "destroy-on-close": "",
    onCancel: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_RequestCard, {
        request: _ctx.request,
        class: "request-pop-up-modal__request-card",
        "use-small-titles": _ctx.$attrs['use-small-titles'],
        "is-reviewing": _ctx.$attrs['is-reviewing'],
        "hide-status-buttons": _ctx.$attrs['hide-status:hide-status-buttons']
      }, null, 8, ["request", "use-small-titles", "is-reviewing", "hide-status-buttons"])
    ]),
    _: 1
  }, 8, ["visible", "onCancel"]))
}