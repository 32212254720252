import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-between simple-card__header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    bordered: false,
    class: _normalizeClass(["simple-card card-shadow", [_ctx.highlighted && 'simple-card--highlighted']])
  }, {
    default: _withCtx(() => [
      (
        _ctx.$slots['title-left'] ||
        _ctx.$slots['title'] ||
        _ctx.$slots['title-right'] ||
        _ctx.title
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "title-left"),
            _renderSlot(_ctx.$slots, "title", {}, () => [
              (_ctx.title)
                ? (_openBlock(), _createBlock(_component_a_typography_title, {
                    key: 0,
                    level: 4,
                    class: "simple-card__title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.title), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _renderSlot(_ctx.$slots, "title-right")
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}