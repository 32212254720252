
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    title: String,
    /** Use to highlight left border with color */
    highlighted: Boolean,
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: {},
  components: {},
});
