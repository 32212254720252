import { ref } from "vue";
import { Requests_requests_RequestsResults_results } from "@/api/order/__generated__/Requests";

export const useRequestPopUpModal = () => {
  const requestPopUpObject = ref<
    Requests_requests_RequestsResults_results | null | undefined
  >();
  const requestPopUpVisible = ref(false);

  const showRequestModal = (
    request: Requests_requests_RequestsResults_results
  ) => {
    requestPopUpObject.value = request;
    requestPopUpVisible.value = true;
  };

  return {
    requestPopUpObject,
    requestPopUpVisible,
    showRequestModal,
  };
};
