
import CustomSteps, {
  CustomStepData,
} from "@/shared/components/CustomSteps.vue";
import SimpleCard from "@/shared/components/SimpleCard.vue";
import DynamicParagraph from "@/shared/components/DynamicParagraph.vue";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { OrderStatus } from "@/shared/types/OrderStatus";
import {
  OrderStatusStepData,
  OrderStatusActionHandler,
} from "@/shared/utils/orderStatusHelper";

export default defineComponent({
  props: {
    currentStepKey: {
      type: String as PropType<OrderStatus>,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    currentStepData: {
      type: Object as PropType<OrderStatusStepData>,
    },
    currentStepActions: {
      type: Object as PropType<OrderStatusActionHandler[]>,
    },
    shotlistSteps: {
      type: Array as PropType<CustomStepData[]>,
      required: true,
    },
    loading: Boolean,
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  components: { SimpleCard, CustomSteps, DynamicParagraph },
});
