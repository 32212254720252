
import { PropType, computed, defineComponent } from "vue";
import Avatar from "@/shared/components/Avatar.vue";

export type CascadingAvatarItem = { src: string; name?: string };

export default defineComponent({
  props: {
    /** To show extra slots. */
    max: Number,
    avatars: Array as PropType<CascadingAvatarItem[]>,
    /** Also disables cascading effect */
    showNames: Boolean,
  },
  setup(props) {
    const extraSlots = computed(() =>
      Math.max((props.max ?? 0) - (props.avatars?.length ?? 0), 0)
    );

    return { extraSlots };
  },
  components: { Avatar },
});
