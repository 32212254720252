import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "download-videos-card__container flex gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!

  return (_openBlock(), _createBlock(_component_SimpleCard, {
    class: "download-videos-card",
    highlighted: ""
  }, {
    title: _withCtx(() => [
      _createVNode(_component_a_typography_title, {
        level: 4,
        class: "custom-color-red"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title || _ctx.t("Here you go!")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_typography_text, { class: "flex-grow next-step-card__action-text" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Download all your videos now.")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_button, {
          type: "primary",
          size: "small",
          class: "custom-red",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-download')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Download All")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}