import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.profileLoading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: ""
        }))
      : _createCommentVNode("", true),
    (_ctx.videoCredits && !_ctx.isExpired)
      ? (_openBlock(), _createBlock(_component_Card, {
          key: 1,
          class: "video-credit-card"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_title, { level: 2 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Video Credits")), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_a_typography_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Video Credits")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_typography_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("video", _ctx.videoCredits.numberOfVideos)), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_typography_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Valid Until")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_typography_text, {
                  class: _normalizeClass([_ctx.isAlmostExpired && 'video-credit-card__almost-expired'])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.creditExpiryDate), 1)
                  ]),
                  _: 1
                }, 8, ["class"])
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                ref: "alertNoteWrapper",
                class: _normalizeClass([
          'video-credit-card__alert-note-wrapper mb-3',
          _ctx.models.seeMoreAnimatied &&
            'video-credit-card__alert-note-wrapper--animated',
        ]),
                style: _normalizeStyle({ height: _ctx.models.seeMoreCurrentHeight })
              }, [
                _createVNode(_component_a_typography_text, { class: "video-credit-card__alert-note" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t(
              "⚠ You are responsible in consuming your video credit. Any video credit not consumed after the expiry date is considered spent and non-refundable."
            )), 1)
                  ]),
                  _: 1
                })
              ], 6),
              _createElementVNode("div", null, [
                _createVNode(_component_a_button, {
                  type: "link",
                  class: "p-0 h-auto",
                  onClick: _ctx.handleSeeMore
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.seeMoreText), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}