import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "p-8 overflow-x-auto" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_CustomSteps = _resolveComponent("CustomSteps")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_DynamicParagraph = _resolveComponent("DynamicParagraph")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!

  return (_openBlock(), _createBlock(_component_SimpleCard, {
    title: _ctx.t('Video Journey'),
    class: "shotlist-card"
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: ""
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_CustomSteps, {
                class: "shotlist-card__steps",
                steps: _ctx.shotlistSteps,
                current: _ctx.currentStepIndex
              }, null, 8, ["steps", "current"])
            ]),
            (_ctx.currentStepData)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (
            !_ctx.currentStepData.hideDescriptionTitle &&
            !_ctx.currentStepData.hideShotlistInstructions
          )
                    ? (_openBlock(), _createBlock(_component_a_typography_title, {
                        key: 0,
                        level: 5
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.currentStepData.descriptionTitle || _ctx.t("Instructions")), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_DynamicParagraph, {
                    text: _ctx.currentStepData.description,
                    class: _normalizeClass([
            'shotlist-card__description',
            _ctx.currentStepData.descriptionColor &&
              `shotlist-card__description--${_ctx.currentStepData.descriptionColor}`,
          ]),
                    "use-html": ""
                  }, null, 8, ["text", "class"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentStepActions, (action) => {
                    return (_openBlock(), _createBlock(_component_a_button, {
                      type: "primary",
                      onClick: action.handler,
                      key: action.label,
                      class: "lighter"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(action.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 1
  }, 8, ["title"]))
}