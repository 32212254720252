
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    text: {
      type: [String, Array] as PropType<string | string[]>,
    },
    useHtml: Boolean,
  },
});
