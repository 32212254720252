import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_SimpleCard, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_a_skeleton, { active: "" })
        ]),
        _: 1
      }))
    : (_ctx.showPanel)
      ? (_openBlock(), _createBlock(_component_SimpleCard, {
          key: 1,
          title: _ctx.t('Shipping Instructions'),
          class: "shipping-instructions-card"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_text, {
              innerHTML: _ctx.order?.shipmentInstructions
            }, null, 8, ["innerHTML"]),
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.redirectToShippingDetails
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Shipping Info")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
}