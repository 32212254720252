
import { Requests_requests_RequestsResults_results } from "@/api/order/__generated__/Requests";
import RequestCard from "@/shared/components/Requests/RequestCard.vue";
import { defineComponent, PropType } from "vue";
import { useVModel } from "vue-composable";

export default defineComponent({
  props: {
    visible: Boolean,
    request:
      Object as PropType<Requests_requests_RequestsResults_results | null>,
    showComments: Boolean,
  },
  setup(props) {
    const visibleModel = useVModel(props, "visible");

    const closeModal = () => {
      visibleModel.value = false;
    };

    return { visibleModel, closeModal };
  },
  components: { RequestCard },
});
