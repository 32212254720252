
import { defineComponent, computed, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useProfile } from "@/shared/composables/useProfile";
import {
  formatDate,
  hasTodayPassedNumberDaysFromDate,
  isDatePassed,
} from "@/shared/utils/date";
import Card from "@/shared/components/Card.vue";
import isUndefined from "lodash/isUndefined";
import debounce from "lodash/debounce";
import delay from "lodash/delay";

type typeSeeMoreHeight = null | string;

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { videoCredits, profileLoading } = useProfile();
    const alertNoteWrapper = ref();

    const models = reactive({
      seeMore: false,
      seeMoreAnimatied: false,
      seeMoreOriginHeight: null as typeSeeMoreHeight,
      seeMoreCurrentHeight: "auto" as typeSeeMoreHeight,
    });

    const creditExpiryDate = computed(() => {
      const date = videoCredits.value?.expiryDate;
      if (!date) {
        return "";
      }
      return formatDate(date, "dateWithNoTimeInWords_dayFirst");
    });

    const isAlmostExpired = computed(() => {
      const date = videoCredits.value?.expiryDate;
      if (!date) {
        return false;
      }
      return hasTodayPassedNumberDaysFromDate(date, 15);
    });

    const isExpired = computed(() => {
      const date = videoCredits.value?.expiryDate;
      if (!date) {
        return false;
      }
      return isDatePassed(date);
    });

    const seeMoreText = computed(() =>
      models.seeMore ? t("Hide") : t("Tell me more")
    );

    /** alertNoteWrapper See More handler: Custom code necessary to add
     * animation to transition. This is due to setting height of element
     * to "Auto" doesn't trigger transition and that is why we need to
     * use the alertNoteWrapper original height */
    const handleSeeMore = () => {
      models.seeMore = !models.seeMore;
      if (models.seeMore) {
        models.seeMoreCurrentHeight = models.seeMoreOriginHeight;
      } else {
        models.seeMoreCurrentHeight = "0px";
      }
    };

    /** alertNoteWrapper Initialization: Store wrapper
     * original height and set to 0px */
    watch(alertNoteWrapper, (e) => {
      if (!models.seeMoreOriginHeight) {
        initSeeMoreHeight(e.clientHeight);
      }
    });

    const initSeeMoreHeight = (elementHeight) => {
      models.seeMoreAnimatied = false;
      models.seeMoreOriginHeight = elementHeight + "px";
      models.seeMoreCurrentHeight = "0px";
      models.seeMore = false;
      models.seeMoreAnimatied = true;
    };

    /** Reinitialize alertNoteWrapper on window resize since it's height
     * might change */
    const onScreenResize = () => {
      models.seeMoreAnimatied = false;
      models.seeMoreCurrentHeight = "auto";
      /** Delay must be done since it takes time for the alertNoteWrapper
       * to get it's full height again */
      delay(() => {
        const elementHeight = alertNoteWrapper?.value?.clientHeight;
        /** Explicit condition to detect if height not undefined */
        if (!isUndefined(elementHeight)) {
          if (elementHeight > 0) {
            initSeeMoreHeight(elementHeight);
          } else {
            models.seeMoreCurrentHeight = "0px";
          }
        }
      }, 10);
      models.seeMoreAnimatied = true;
    };
    window.addEventListener("resize", debounce(onScreenResize, 100));

    return {
      t,
      videoCredits,
      profileLoading,
      creditExpiryDate,
      isAlmostExpired,
      isExpired,
      models,
      seeMoreText,
      handleSeeMore,
      alertNoteWrapper,
    };
  },
  components: {
    Card,
  },
});
