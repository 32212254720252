
import { Order_order_Order } from "@/api/order/__generated__/Order";
import Card from "@/shared/components/Card.vue";
import DynamicParagraph from "@/shared/components/DynamicParagraph.vue";
import SimpleCard from "@/shared/components/SimpleCard.vue";
import VideoCreditCard from "@/shared/components/VideoCreditCard.vue";
import ContactUsCard from "@/shared/components/ContactUsCard.vue";
import OtherLinksCard from "@/web/views/Profile/Cards/OtherLinksCard.vue";
import RequestsCard from "@/web/views/Profile/Cards/RequestsCard.vue";
import SelectPerformerApplicationsCard from "@/web/views/Profile/Cards/SelectPerformerApplicationsCard.vue";
import ShippingInstructionsCard from "@/web/views/Profile/Cards/ShippingInstructionsCard.vue";
import ShotlistCard from "@/web/views/Profile/Cards/ShotlistCard.vue";
import TakeActionCard from "@/web/views/Profile/Cards/TakeActionCard.vue";
import NextStepCard from "@/web/views/Profile/Cards/NextStepCard.vue";
import {
  ORDER_STATUS_ALLOW_DOWNLOAD_VIDEOS,
  ORDER_STATUS_ALLOW_PERFORMER_UPDATE,
} from "@/shared/composables/useOrder";
import { useOrderStatusHelper } from "@/shared/composables/useOrderStatusHelper";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import {
  OUTPUT_VIDEO_DOWNLOADABLE_JOB_STATUSES,
  useRequests,
} from "@/shared/composables/useRequests";
import { OrderStatus } from "@/shared/types/OrderStatus";
import { formatDate } from "@/shared/utils/date";
import { getOrderName } from "@/shared/utils/orderUtils";
import { PropType, computed, defineComponent, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { QuotationType } from "@/shared/utils/orderUtils";
import { QUOTATION_STATUS } from "@/shared/types/QuotationStatus";
import DownloadVideosCard from "@/web/views/Profile/Cards/DownloadVideosCard.vue";
import { useSubscriptionPlans } from "@/shared/composables/useSubscriptionPlans";

export default defineComponent({
  props: {
    order: Object as PropType<Order_order_Order | null | undefined>,
    quotation: Object as PropType<QuotationType | null>,
    loading: Boolean,
    quotationLoading: Boolean,
  },
  setup(props) {
    const { t } = useI18n();

    const { currentSubscriptionPlan } = useSubscriptionPlans();

    /**
     * Use these *Ref values instead of the props.
     * In /profile, this component can show a UI without an `Order`,
     * but only a `Quotation` that is also considered a future `Order`.
     */
    const orderRef = computed(() => props.order);
    const quotationRef = computed(
      () => props.quotation || orderRef.value?.quotation
    );
    const loadingRef = computed(() => props.loading);

    const maxPerformerCount = computed(
      () =>
        orderRef.value?.quotation?.numOfPerformers ??
        currentSubscriptionPlan.value?.maxPerformerCount ??
        0
    );

    const canSelectPerformers = computed(() =>
      ORDER_STATUS_ALLOW_PERFORMER_UPDATE.includes(
        orderRef.value?.status?.key as OrderStatus
      )
    );

    const {
      redirectToDownloadRequests,
      redirectToQuote,
      redirectToQuoteSummary,
    } = useRedirectHelper();

    const handleRedirectToQuote = () => {
      redirectToQuote({ quotationId: quotationRef.value?.id });
    };

    const handleRedirectToQuoteSummary = () => {
      redirectToQuoteSummary({ quotationId: quotationRef.value?.id });
    };

    const {
      requestsFilter,
      requestsLoading,
      requests,
      requestsHasNextPage,
      requestsFetchMore,
      requestsFetchMoreLoading,
    } = useRequests();

    const {
      currentStepKey,
      currentStepIndex,
      currentStepData,
      currentStepActions,
      currentStepFaq,
      shotlistSteps,
      targetDateString,
    } = useOrderStatusHelper(orderRef, quotationRef);

    watchEffect(() => {
      if (orderRef.value) {
        requestsFilter.orderId = Number(orderRef.value.id);

        // Filter out only downloadable videos when status is in CLIENT_REVIEW (Select Videos)
        // See: #865czxcgj
        if (orderRef.value.status?.key === OrderStatus.CLIENT_REVIEW) {
          requestsFilter.statusKeys = OUTPUT_VIDEO_DOWNLOADABLE_JOB_STATUSES;
        }
      }
    });

    const redirectToDownloadPage = () => {
      redirectToDownloadRequests({ orderId: orderRef.value?.id });
    };

    // FIXME: Duplicated code from `useOrder` composable
    const isAllowedDownloadVideos = computed<boolean>(
      () =>
        !orderRef.value?.videoOutputLink &&
        ORDER_STATUS_ALLOW_DOWNLOAD_VIDEOS.includes(
          orderRef.value?.status?.key as OrderStatus
        )
    );

    const orderDetailsList = computed<{ label: string; value?: string }[]>(
      () => [
        {
          label: t("Order Created"),
          value: orderRef.value?.created
            ? formatDate(
                orderRef.value.created,
                "dateWithNoTimeInWords_dayFirst"
              )
            : "",
        },
        {
          label: t("Number of Videos"),
          value: quotationRef.value?.numOfVideos
            ? String(quotationRef.value?.numOfVideos)
            : "",
        },
        {
          label: t("Number of Model Creator/s"),
          value: quotationRef.value?.numOfPerformers
            ? String(quotationRef.value?.numOfPerformers)
            : "",
        },
      ]
    );

    return {
      t,
      maxPerformerCount,
      canSelectPerformers,
      OrderStatus,
      isNewQuotation: computed(
        () => quotationRef.value?.status?.key === QUOTATION_STATUS.NEW
      ),
      quotationEditable: computed(
        () =>
          ![OrderStatus.DONE, OrderStatus.CANCELED].includes(
            orderRef.value?.status?.key as OrderStatus
          )
      ),
      orderRef,
      currentStepKey,
      currentStepIndex,
      currentStepData,
      currentStepActions,
      currentStepFaq,
      shotlistSteps,
      showDownloadVideosPanel: computed(() =>
        ORDER_STATUS_ALLOW_DOWNLOAD_VIDEOS.includes(
          orderRef.value?.status?.key as OrderStatus
        )
      ),
      showTakeActionPanel: computed(
        () =>
          // If has actions
          (currentStepActions.value.length ||
            // Or has dedicated Take Action Description
            currentStepData.value?.takeActionDescription ||
            // OR, if in SHIP_PRODUCT (exception)
            orderRef?.value?.status?.key === OrderStatus.SHIP_PRODUCT) &&
          // AND, is not still loading
          !loadingRef.value
      ),
      redirectToDownloadPage,
      requestsLoading,
      requests,
      shownQuotation: quotationRef,
      isAllowedDownloadVideos,
      targetDateString,
      formatDate,
      loadingRef,
      getOrderName,
      orderDetailsList,
      hasNoOrder: computed(() => !orderRef.value && !!quotationRef.value),
      handleRedirectToQuote,
      handleRedirectToQuoteSummary,
      requestsHasNextPage,
      requestsFetchMore,
      requestsFetchMoreLoading,
    };
  },
  methods: {},
  components: {
    ShotlistCard,
    OtherLinksCard,
    SelectPerformerApplicationsCard,
    ShippingInstructionsCard,
    RequestsCard,
    DynamicParagraph,
    Card,
    SimpleCard,
    TakeActionCard,
    NextStepCard,
    VideoCreditCard,
    ContactUsCard,
    DownloadVideosCard,
  },
});
