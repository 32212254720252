
import { Requests_requests_RequestsResults_results } from "@/api/order/__generated__/Requests";
import Avatar from "@/shared/components/Avatar.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import RequestCardStatus from "@/shared/components/Requests/RequestCardStatus.vue";
import { usePerformerDetails } from "@/shared/composables/usePerformerDetails";
import { useVimeoEmbed } from "@/shared/composables/useVimeoEmbed";
import { isOutputVideoViewable } from "@/shared/composables/useRequests";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { fallbackSrc } from "@/shared/utils/constants";
import { JobStatus } from "@/shared/types/JobStatus";

export default defineComponent({
  props: {
    request: {
      type: Object as PropType<Requests_requests_RequestsResults_results>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const { getVimeoThumbnail } = useVimeoEmbed();

    const { performerDetails, performerDetailsLoading } = usePerformerDetails(
      {
        input: { id: props.request.performer?.id ?? "" },
      },
      { hideError: true }
    );

    const requestingForRevisions = computed(
      () => props.request?.status?.key === JobStatus.CLIENT_COMMENTED
    );

    return {
      t,
      getVimeoThumbnail,
      performerDetails,
      performerDetailsLoading,
      fallbackSrc,
      isOutputVideoViewable,
      requestingForRevisions,
    };
  },
  components: {
    Avatar,
    AppIcon,
    RequestCardStatus,
  },
});
