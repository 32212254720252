import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_CascadingAvatars = _resolveComponent("CascadingAvatars")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!

  return (_openBlock(), _createBlock(_component_SimpleCard, {
    class: "select-performer-applications-card",
    title: 
      _ctx.canSelect ? _ctx.t('Select Model Creators') : _ctx.t('Selected Model Creators')
    
  }, {
    default: _withCtx(() => [
      (_ctx.canSelect)
        ? (_openBlock(), _createBlock(_component_a_typography_text, {
            key: 0,
            class: "block mt-4"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Check out the model creators who applied for your project.")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CascadingAvatars, {
          avatars: _ctx.shownAvatars,
          max: _ctx.max,
          "show-names": !_ctx.canSelect
        }, null, 8, ["avatars", "max", "show-names"])
      ]),
      (_ctx.canSelect)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 1,
            type: "secondary",
            class: "mt-8",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToPerformerApplications({ orderId: _ctx.order?.id })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Select Model Creators")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}