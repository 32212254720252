import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['cascading-avatar', _ctx.showNames && 'cascading-avatar--with-names'])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avatars, (avatar) => {
      return (_openBlock(), _createElementBlock("div", {
        key: avatar.src,
        class: "cascading-avatar__container"
      }, [
        _createVNode(_component_Avatar, {
          "avatar-src": avatar.src,
          class: "cascading-avatar__avatar"
        }, null, 8, ["avatar-src"]),
        (_ctx.showNames)
          ? (_openBlock(), _createBlock(_component_a_typography_text, {
              key: 0,
              class: "cascading-avatar__name"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(avatar.name), 1)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extraSlots, (index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "cascading-avatar__container"
      }, [
        _createVNode(_component_Avatar, { class: "cascading-avatar__avatar" })
      ]))
    }), 128))
  ], 2))
}