
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import { MailOutlined } from "@ant-design/icons-vue";
import SimpleCard from "@/shared/components/SimpleCard.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const supportEmail = config.merchantSupportEmailUrl;
    return {
      t,
      supportEmail,
    };
  },
  components: {
    SimpleCard,
    MailOutlined,
  },
});
