
import { Order_order_Order } from "@/api/order/__generated__/Order";
import { Requests_requests_RequestsResults_results } from "@/api/order/__generated__/Requests";
import { i18nTranslate } from "@/plugins/i18n";
import RequestCardListItem from "@/shared/components/Requests/RequestCardListItem.vue";
import SimpleCard from "@/shared/components/SimpleCard.vue";
import { ORDER_STATUS_ALLOW_DOWNLOAD_VIDEOS } from "@/shared/composables/useOrder";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { useRequestPopUpModal } from "@/shared/composables/useRequestPopUpModal";
import { OrderStatus } from "@/shared/types/OrderStatus";
import RequestPopUpModal from "@/web/views/Requests/RequestPopUpModal.vue";
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    order: Object as PropType<Order_order_Order>,
    requests: Array as PropType<Requests_requests_RequestsResults_results[]>,
    requestsLoading: Boolean,
    requestsHasNextPage: Boolean,
    requestsFetchMoreLoading: Boolean,
  },
  emits: ["on-load-more"],
  setup(props) {
    const { t } = useI18n();

    const { redirectToDownloadRequests } = useRedirectHelper();

    const { requestPopUpObject, requestPopUpVisible, showRequestModal } =
      useRequestPopUpModal();

    const handleRequestCardListItemClick = (request) => {
      showRequestModal(request);
    };

    const isDownloadComplete = computed(() =>
      ORDER_STATUS_ALLOW_DOWNLOAD_VIDEOS.includes(
        props.order?.status?.key as OrderStatus
      )
    );

    const downloadMessage = computed(() => {
      const isDownloadAvailable = props.requests?.some(
        (r) => r.isDownloadAvailable
      );

      if (isDownloadComplete.value) {
        return i18nTranslate("You can now download all your videos now.");
      } else if (isDownloadAvailable) {
        return i18nTranslate(
          "You can now proceed to download the videos that you have approved."
        );
      }

      return "";
    });

    const handleDownload = () => {
      redirectToDownloadRequests({ orderId: props.order?.id });
    };

    return {
      t,
      requestPopUpObject,
      showRequestModal,
      requestPopUpVisible,
      handleRequestCardListItemClick,
      downloadMessage,
      isDownloadComplete,
      handleDownload,
    };
  },
  components: { SimpleCard, RequestCardListItem, RequestPopUpModal },
});
