import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MailOutlined = _resolveComponent("MailOutlined")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!

  return (_openBlock(), _createBlock(_component_SimpleCard, { class: "contact-us-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_typography_title, { level: 4 }, {
        default: _withCtx(() => [
          _createVNode(_component_MailOutlined),
          _createTextVNode(" " + _toDisplayString(_ctx.t("Contact Our Team")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_text, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("For any questions or concerns, please email us at")) + " ", 1),
          _createVNode(_component_a_button, {
            type: "link",
            href: 'mailto:' + _ctx.supportEmail,
            class: "px-0"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.supportEmail), 1)
            ]),
            _: 1
          }, 8, ["href"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}