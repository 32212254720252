import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "dynamic-paragraph" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (typeof _ctx.text === 'string' && !!_ctx.text)
      ? (_openBlock(), _createBlock(_component_a_typography_paragraph, { key: 0 }, {
          default: _withCtx(() => [
            (_ctx.useHtml)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  innerHTML: _ctx.text
                }, null, 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.text), 1))
          ]),
          _: 1
        }))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.text, (paragraph) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: paragraph }, [
            (!!paragraph)
              ? (_openBlock(), _createBlock(_component_a_typography_paragraph, { key: 0 }, {
                  default: _withCtx(() => [
                    (_ctx.useHtml)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: paragraph
                        }, null, 8, _hoisted_4))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(paragraph), 1))
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
  ]))
}