import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "requests-card-list-item__main-row flex gap-4 items-center" }
const _hoisted_2 = { class: "requests-card-list-item__thumbnail relative" }
const _hoisted_3 = { class: "requests-card-list-item__thumbnail-mask absolute inset-0 flex items-center justify-center" }
const _hoisted_4 = { class: "requests-card-list-item__text-col flex gap-4 flex-grow items-center" }
const _hoisted_5 = { class: "flex-grow" }
const _hoisted_6 = { class: "flex items-center gap-2" }
const _hoisted_7 = {
  key: 0,
  class: "requests-card-list-item__status flex-shrink-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_a_typography = _resolveComponent("a-typography")!
  const _component_RequestCardStatus = _resolveComponent("RequestCardStatus")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "requests-card-list-item w-full cursor-pointer" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_image, {
            src: 
            _ctx.isOutputVideoViewable(_ctx.request)
              ? _ctx.getVimeoThumbnail(_ctx.request.outputVideos.at(0)?.embedUrl) || '#'
              : '#'
          ,
            fallback: _ctx.fallbackSrc,
            preview: false
          }, null, 8, ["src", "fallback"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AppIcon, { name: "playButtonWhite" })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.request.id)
              ? (_openBlock(), _createBlock(_component_a_typography_text, {
                  key: 0,
                  class: "text-tag block"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Video #{number}", {
                number: _ctx.request.id,
              })), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_a_typography_title, {
              level: 5,
              class: "mt-2"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.request.template?.title), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Avatar, {
                "avatar-src": _ctx.performerDetails?.performerProfile?.avatar,
                class: "w-6",
                "no-border": ""
              }, null, 8, ["avatar-src"]),
              _createVNode(_component_a_typography_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Assigned to {performerName}", {
                  performerName: _ctx.performerDetails?.performerProfile?.nickname,
                })), 1)
                ]),
                _: 1
              })
            ]),
            (_ctx.requestingForRevisions)
              ? (_openBlock(), _createBlock(_component_a_typography, {
                  key: 1,
                  class: "text-xs mt-2 text-color-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("*Please wait until our team reviews your comments.")), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          (_ctx.request.status)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_RequestCardStatus, { request: _ctx.request }, null, 8, ["request"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}