
import { defineComponent, computed, PropType } from "vue";
import { useI18n } from "vue-i18n";
import SimpleCard from "@/shared/components/SimpleCard.vue";
import { OrderStatus } from "@/shared/types/OrderStatus";
import { Order_order_Order } from "@/api/order/__generated__/Order";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";

export default defineComponent({
  props: {
    order: Object as PropType<Order_order_Order>,
    loading: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const { redirectToOrderShippingDetails } = useRedirectHelper();

    const redirectToShippingDetails = () => {
      redirectToOrderShippingDetails({ orderId: props.order?.id });
    };

    return {
      t,
      OrderStatus,
      redirectToShippingDetails,
      showPanel: computed(() => {
        return (
          props.order &&
          !!props.order.shipmentInstructions &&
          // Only show when status is greater than or equal to SHIP_PRODUCT
          [
            OrderStatus.SHIP_PRODUCT,
            OrderStatus.IDEATION,
            OrderStatus.PRODUCTION,
            OrderStatus.QA,
            OrderStatus.CLIENT_REVIEW,
            OrderStatus.CLIENT_FEEDBACK,
          ].includes(props.order.status?.key as OrderStatus)
        );
      }),
    };
  },
  components: { SimpleCard },
});
