import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "take-action-card__container flex gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!

  return (_openBlock(), _createBlock(_component_SimpleCard, {
    title: _ctx.title || _ctx.t('Take Action'),
    class: "take-action-card",
    highlighted: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_typography_text, { class: "flex-grow take-action-card__action-text" }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "text")
          ]),
          _: 3
        })
      ])
    ]),
    _: 3
  }, 8, ["title"]))
}