
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import Steps, { Step } from "ant-design-vue/lib/steps";
import "ant-design-vue/lib/steps/style";
import { useVModel } from "vue-composable";
import LayoutIcon, {
  LayoutIconName,
} from "@/shared/components/Icons/LayoutIcon.vue";

/**
 * Interface for data used in CustomSteps
 * Support for icons above title
 */
export interface CustomStepData {
  title: string;
  iconName?: LayoutIconName;
  subtitle?: string;
  showSubtitleAlways?: boolean;
}

export default defineComponent({
  props: {
    steps: Array as PropType<CustomStepData[]>,
    current: Number,
  },
  setup(props) {
    const { t } = useI18n();
    const localCurrent = useVModel(props, "current");

    return { t, localCurrent };
  },
  components: { Steps, Step, LayoutIcon },
});
