
import SimpleCard from "@/shared/components/SimpleCard.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  emits: ["on-download"],
  props: {
    title: String,
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  components: { SimpleCard },
});
