import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative custom-step__content" }
const _hoisted_2 = {
  key: 0,
  class: "custom-step__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_Step = _resolveComponent("Step")!
  const _component_Steps = _resolveComponent("Steps")!

  return (_openBlock(), _createBlock(_component_Steps, {
    current: _ctx.localCurrent,
    class: "custom-steps",
    size: "small",
    labelPlacement: "vertical",
    responsive: ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
        return (_openBlock(), _createBlock(_component_Step, {
          key: step.title,
          class: _normalizeClass({
        'custom-step-last-finished': index === (_ctx.localCurrent ?? 0) - 1,
      })
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (step.iconName)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_LayoutIcon, {
                      name: step.iconName,
                      class: "w-full h-full object-contain p-2"
                    }, null, 8, ["name"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_a_typography_text, { class: "custom-step__title" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(step.title), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            (
            step.subtitle &&
            // Only show if not hiding when inactive, or when hiding, but is active
            (step.showSubtitleAlways ||
              (!step.showSubtitleAlways && index === (_ctx.localCurrent ?? 0)))
          )
              ? (_openBlock(), _createBlock(_component_a_typography_text, {
                  key: 0,
                  class: "custom-step__subtitle"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(step.subtitle), 1)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["current"]))
}