import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography = _resolveComponent("a-typography")!
  const _component_RequestCardListItem = _resolveComponent("RequestCardListItem")!
  const _component_RequestPopUpModal = _resolveComponent("RequestPopUpModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.requestsLoading && !_ctx.requestsFetchMoreLoading)
      ? (_openBlock(), _createBlock(_component_SimpleCard, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_skeleton, { active: "" })
          ]),
          _: 1
        }))
      : (_ctx.order && _ctx.requests?.length)
        ? (_openBlock(), _createBlock(_component_SimpleCard, {
            key: 1,
            class: "requests-card"
          }, {
            title: _withCtx(() => [
              _createVNode(_component_a_typography_title, {
                level: 4,
                class: "m-0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Current Order")), 1)
                ]),
                _: 1
              }),
              (_ctx.isDownloadComplete)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    type: "primary",
                    size: "small",
                    class: "requests-card__download-all-btn custom-red",
                    onClick: _ctx.handleDownload
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Download All")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              (_ctx.downloadMessage)
                ? (_openBlock(), _createBlock(_component_a_typography, {
                    key: 0,
                    class: "mt-7 mb-4"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.downloadMessage), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requests, (request) => {
                  return (_openBlock(), _createBlock(_component_RequestCardListItem, {
                    key: request?.id,
                    request: request,
                    onClick: ($event: any) => (_ctx.handleRequestCardListItemClick(request))
                  }, null, 8, ["request", "onClick"]))
                }), 128))
              ]),
              (_ctx.requestsHasNextPage)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 1,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-load-more'))),
                    loading: _ctx.requestsFetchMoreLoading,
                    class: "mt-8 mx-auto block"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("See More")), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
    _createVNode(_component_RequestPopUpModal, {
      visible: _ctx.requestPopUpVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.requestPopUpVisible) = $event)),
      request: _ctx.requestPopUpObject,
      "hide-status-buttons": ""
    }, null, 8, ["visible", "request"])
  ], 64))
}