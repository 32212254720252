
import { Order_order_Order } from "@/api/order/__generated__/Order";
import CascadingAvatars, {
  CascadingAvatarItem,
} from "@/shared/components/CascadingAvatars.vue";
import SimpleCard from "@/shared/components/SimpleCard.vue";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    order: Object as PropType<Order_order_Order>,
    canSelect: Boolean,
    max: Number,
  },
  setup(props) {
    const { t } = useI18n();
    const { redirectToPerformerApplications } = useRedirectHelper();

    /** Show Performer Applications if still can select/update, else show Selected Performers */
    const shownAvatars = computed<CascadingAvatarItem[]>(() =>
      props.canSelect
        ? props.order?.performerApplications.map((application) => ({
            src: application.performer?.performerProfile?.avatar ?? "",
            name: application.performer?.performerProfile?.nickname,
          })) ?? []
        : props.order?.performers.map((performer) => ({
            src: performer?.performerProfile?.avatar ?? "",
            name: performer?.performerProfile?.nickname,
          })) ?? []
    );

    return {
      t,
      redirectToPerformerApplications,
      shownAvatars,
    };
  },
  methods: {},
  components: {
    SimpleCard,
    CascadingAvatars,
  },
});
