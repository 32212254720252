import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, Transition as _Transition, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "order-detail-display__container"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "order-detail-display__order-id" }
const _hoisted_5 = { class: "flex flex-col gap-2" }
const _hoisted_6 = { class: "flex justify-between flex-wrap pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_VideoCreditCard = _resolveComponent("VideoCreditCard")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SelectPerformerApplicationsCard = _resolveComponent("SelectPerformerApplicationsCard")!
  const _component_ShippingInstructionsCard = _resolveComponent("ShippingInstructionsCard")!
  const _component_OtherLinksCard = _resolveComponent("OtherLinksCard")!
  const _component_ContactUsCard = _resolveComponent("ContactUsCard")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_DownloadVideosCard = _resolveComponent("DownloadVideosCard")!
  const _component_DynamicParagraph = _resolveComponent("DynamicParagraph")!
  const _component_TakeActionCard = _resolveComponent("TakeActionCard")!
  const _component_NextStepCard = _resolveComponent("NextStepCard")!
  const _component_ShotlistCard = _resolveComponent("ShotlistCard")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_SimpleCard = _resolveComponent("SimpleCard")!
  const _component_RequestsCard = _resolveComponent("RequestsCard")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_ctx.loadingRef)
    ? (_openBlock(), _createBlock(_component_a_skeleton, {
        key: 0,
        active: ""
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_row, { gutter: [36, 36] }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 24,
              xl: 8,
              class: "flex flex-col gap-8"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_VideoCreditCard),
                _createVNode(_component_Card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_title, { level: 2 }, {
                      default: _withCtx(() => [
                        (_ctx.hasNoOrder)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.t("Order for ")), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.t("Order ")), 1)),
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getOrderName({ order: _ctx.order, quotation: _ctx.quotation })), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderDetailsList, (detail) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: detail.label,
                          class: "flex justify-between"
                        }, [
                          _createVNode(_component_a_typography_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(detail.label), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_typography_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(detail.value || _ctx.t("-")), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128)),
                      _createElementVNode("div", _hoisted_6, [
                        (_ctx.isNewQuotation)
                          ? (_openBlock(), _createBlock(_component_a_button, {
                              key: 0,
                              type: "link",
                              class: "p-0 h-auto",
                              onClick: _ctx.handleRedirectToQuote
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("Get a Quote")), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : (_ctx.shownQuotation)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                (_ctx.quotationEditable)
                                  ? (_openBlock(), _createBlock(_component_a_button, {
                                      key: 0,
                                      type: "link",
                                      class: "p-0 h-auto",
                                      onClick: _ctx.handleRedirectToQuote
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t("Change the Plan")), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["onClick"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_a_button, {
                                  type: "link",
                                  class: "p-0 h-auto",
                                  onClick: _ctx.handleRedirectToQuoteSummary
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t("View Current Plan")), 1)
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ], 64))
                            : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _: 1
                }),
                (_ctx.order)
                  ? (_openBlock(), _createBlock(_component_SelectPerformerApplicationsCard, {
                      key: 0,
                      order: _ctx.order,
                      max: _ctx.maxPerformerCount,
                      id: "selected-performers-card",
                      "can-select": _ctx.canSelectPerformers
                    }, null, 8, ["order", "max", "can-select"]))
                  : _createCommentVNode("", true),
                (_ctx.order)
                  ? (_openBlock(), _createBlock(_component_ShippingInstructionsCard, {
                      key: 1,
                      order: _ctx.order,
                      id: "shipping-instructions-card"
                    }, null, 8, ["order"]))
                  : _createCommentVNode("", true),
                (_ctx.order)
                  ? (_openBlock(), _createBlock(_component_OtherLinksCard, {
                      key: 2,
                      order: _ctx.order,
                      id: "other-links-card"
                    }, null, 8, ["order"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ContactUsCard)
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 24,
              xl: 16,
              class: "flex flex-col gap-8"
            }, {
              default: _withCtx(() => [
                _createVNode(_Transition, {
                  name: "slide-fade",
                  appear: ""
                }, {
                  default: _withCtx(() => [
                    (_ctx.showDownloadVideosPanel)
                      ? (_openBlock(), _createBlock(_component_DownloadVideosCard, {
                          key: 0,
                          title: 
              _ctx.orderRef?.status?.key === _ctx.OrderStatus.CLIENT_FEEDBACK
                ? _ctx.t(`You're almost there!`)
                : _ctx.t(`Here you go!`)
            ,
                          onOnDownload: _ctx.redirectToDownloadPage
                        }, null, 8, ["title", "onOnDownload"]))
                      : (_ctx.showTakeActionPanel)
                        ? (_openBlock(), _createBlock(_component_TakeActionCard, {
                            key: 1,
                            id: "take-action-card"
                          }, {
                            text: _withCtx(() => [
                              _createVNode(_component_DynamicParagraph, {
                                text: 
                  _ctx.currentStepData?.takeActionDescription ||
                  _ctx.currentStepData?.description
                ,
                                "use-html": ""
                              }, null, 8, ["text"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (_ctx.currentStepFaq.nextStep)
                  ? (_openBlock(), _createBlock(_component_NextStepCard, { key: 0 }, _createSlots({
                      text: _withCtx(() => [
                        _createVNode(_component_DynamicParagraph, {
                          text: _ctx.currentStepFaq.nextStep.answer,
                          "use-html": ""
                        }, null, 8, ["text"])
                      ]),
                      _: 2
                    }, [
                      (!!_ctx.currentStepFaq.nextStep.nextStepAction)
                        ? {
                            name: "action",
                            fn: _withCtx(() => [
                              _createVNode(_component_a_button, {
                                type: "primary",
                                onClick: _ctx.currentStepFaq.nextStep.nextStepAction,
                                size: "small"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.currentStepFaq.nextStep.nextStepActionLabel), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ])
                          }
                        : undefined
                    ]), 1024))
                  : _createCommentVNode("", true),
                _createVNode(_component_ShotlistCard, {
                  id: "shotlist-card",
                  "shotlist-steps": _ctx.shotlistSteps,
                  "current-step-index": _ctx.currentStepIndex,
                  "current-step-key": _ctx.currentStepKey,
                  "current-step-data": _ctx.currentStepData,
                  "current-step-actions": _ctx.currentStepActions,
                  loading: _ctx.loading
                }, null, 8, ["shotlist-steps", "current-step-index", "current-step-key", "current-step-data", "current-step-actions", "loading"]),
                (_ctx.currentStepFaq.faqs.length)
                  ? (_openBlock(), _createBlock(_component_SimpleCard, {
                      key: 1,
                      title: _ctx.t('FAQ')
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentStepFaq.faqs, (faq) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: faq.question,
                            class: "mt-2"
                          }, [
                            _createVNode(_component_a_typography_title, { level: 6 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(faq.question), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_typography_paragraph, {
                              innerHTML: faq.answer
                            }, null, 8, ["innerHTML"])
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (_ctx.order)
                  ? (_openBlock(), _createBlock(_component_RequestsCard, {
                      key: 2,
                      order: _ctx.order,
                      requests: _ctx.requests,
                      "requests-loading": _ctx.requestsLoading,
                      "requests-fetch-more-loading": _ctx.requestsFetchMoreLoading,
                      "requests-has-next-page": _ctx.requestsHasNextPage,
                      onOnLoadMore: _ctx.requestsFetchMore
                    }, null, 8, ["order", "requests", "requests-loading", "requests-fetch-more-loading", "requests-has-next-page", "onOnLoadMore"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
}