
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import SimpleCard from "@/shared/components/SimpleCard.vue";
import { Order_order_Order } from "@/api/order/__generated__/Order";
import { openInNewTab } from "@/shared/utils/browser";

export type LinkData = {
  label?: string;
  url?: string;
};

export default defineComponent({
  props: {
    order: Object as PropType<Order_order_Order>,
    loading: Boolean,
  },
  setup(props) {
    const { t } = useI18n();

    const shownLinksData = computed<LinkData[]>(() =>
      [
        {
          label: t("View your content"),
          url: props.order?.scriptLink,
        },
      ].filter((item) => !!item.url)
    );

    /**
     * Only show this component when there is any link to show
     */
    const showPanel = computed(() => !!shownLinksData.value.length);

    return {
      t,
      showPanel,
      shownLinksData,
    };
  },
  methods: { openInNewTab },
  components: { SimpleCard },
});
